import styles from "./styles.module.css";
import Image from "@/shared/components/Image/new";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames'

const Opinion = (props) => {
	const { content } = props;
	
	const nextDomain = process.env.NEXT_PUBLIC_DOMAIN_URL
	const defaultAuthorImg = `${nextDomain}/img/opinion_generica.gif`

	return (
		<div className={`${styles["opinion-parent"]}`}>
			{content &&
				content.map((element, id) => {
					const { url, title, subtitle, authors } = element;
					const author = authors && authors.length > 0 ? authors[0] : {};
					const authorName = formatAuthorNames(author.name);
					return (
						<div className={`${styles["opinion-container"]}`} key={element + id}>
							<div className={styles["autor-container"]}>
								<div className={` ${styles["opinion-img"]}`}>
									{author && author.slug ? (
										<a href={`/autor/${author.slug}`}>
											<Image
												src={`${nextDomain}${author.imageUrl}`}
												alt={authorName || "Imagen del Autor"}
												width={74}
												height={74}
												className={styles["img"]}
											/>
										</a>
									) : (
										<Image
											src={defaultAuthorImg}
											alt={authorName || "Imagen del Autor"}
											width={74}
											height={74}
											className={styles["img"]}
										/>
									)}
									
								</div>
								{ authorName && author.slug &&
									<a href={`/autor/${author.slug}`}>
										<h5>{authorName}</h5>
									</a>
									}

							</div>
							<div className={`${styles["opinion-txt-container"]}`}>
								<a href={url}>
									<h1>{subtitle}</h1>
									<h2>{title}</h2>
								</a>
							</div>
									
						</div>
					);
				})}
		</div>
	);
};
export default Opinion;
